<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入标题" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.cid" placeholder="请选择" size="small" clearable>
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column>
      <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="title"
              label="标题">
      </el-table-column>
      <el-table-column
          prop="pid_str"
          align="center"
          width="150"
          label="分类">
        <template slot-scope="scope">
          <span v-if="scope.row.cid==1">企业价值观</span>
          <span v-if="scope.row.cid==2">发展愿景</span>
          <span v-if="scope.row.cid==3">使命</span>
        </template>
      </el-table-column>
      <el-table-column
              prop="img"
              label="图片"
              align="center"
              width="120">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="60" :src="scope.row.img"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuList: [
        {id:1,title:'企业价值观'},
        {id:2,title:'发展愿景'},
        {id:3,title:'使命'}
      ],
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        pid: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        cid: that.search.cid,
      }
      this.$api.about.aboutHonorIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.$router.push({name: 'about_honor_edit'})
    },
    editGroup(item) {
      this.$router.push({name: 'about_honor_edit', query: {id: item.id}})
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.about.aboutHonorDel({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
